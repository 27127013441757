.container {
    height: 100%;
}

.header {
    display: flex;
    justify-content: center;

}

.header sub {
    color: #ffffff;
    font-size: 12px;
}

.h1 {
    color: #ffffff;
    font-size: 25px;
}

.content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
}


.card {
    margin: 0;
}

@media (min-width: 576px) {
    .card {
        margin: 0 0 20px 20px;
    }

    .bottomSpacer {
        display: none;
    }
}

.bottomSpacer {
    height: 20px;
    width: 298px;
}

.dotConnected {
    height: 15px;
    width: 15px;
    background-color: #38bb64;
    border-radius: 50%;
    display: inline-block;
}

.dotDisconnected {
    height: 15px;
    width: 15px;
    background-color: #bb8d14;
    border-radius: 50%;
    display: inline-block;
}

