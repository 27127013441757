.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.header {
    display: flex;
    justify-content: center;
}

.h1 {
    color: #ffffff;
    font-size: 25px;
}